.App {
  text-align: center;
  position: relative;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-text-justify{
  text-justify: auto;
}

.start-text{
  margin-top: 30px;
  font-size: 16px;
}
.intro-label {
  width: 100%;
  padding: 8px 8px;
  margin-top: 20px;
  font-weight: bold;
  font-style: italic;
  text-align: left;
  font-size: 16px;
 
}

.intro-label-new{
  padding: 8px 0px;
  margin-top: 20px;
  font-weight: bold;
  text-align: left;
  font-size: 30px;
  margin: auto;
}

.intro-sub-label {
  font-size: 18px;
  margin-bottom: 30px;  
  text-align: left;
}

.form-element {
  width: 35%;
  margin: auto;
}
.required-list{
  margin: auto;
  background-color: #f5ebda;
  margin-bottom: 30px;
}
.step-number-element {
  background-color: #CCCCCC;
  padding: 8px 8px;
  margin-top: 10px;
  text-align: left;
  font-weight: bold;
}

.next-enable-button-element,
.next-disable-button-element {
  width: 50%;
  border-radius: 7px;

}

.next-enable-button-element:hover,
.next-disable-button-element:hover:disabled,
.btn:hover {
  background-color: #3860B2;
  border: none;
  box-shadow: none;
}
.back-button {
  background-color:#000;
}
.form-group {
  margin-bottom: 20px;
}

input[type="text"],
input[type="email"],
input[type="date"],
select {
  border: 1.5px solid #808080;
  border-radius: 5px;
  height: 40px;
  width: 100%;
}
input[type="text"],
input[type="email"],
input[type="date"]{
  user-select: none;
  text-align: left !important;
}


input[type="text"]:focus,
input[type="email"]:focus {
  box-shadow: none;
  border: 1.5px solid #808080;
}

input[type="date"]:focus-visible,
select:focus-visible {
  outline: none;
}

.asterisk-element {
  color: #FF9718;
  font-size: 18px;
  font-weight: bold;
  margin-left: 4px;
  margin-right: 4px;
}

::-webkit-calendar-picker-indicator {
  background-color: #CCCCCC;
  padding: 10px 10px 10px 10px;
}

.tooltip-icon {
  cursor: pointer;

}

.tooltip-icon .tooltiptext {
  visibility: hidden;
  background-color: #CCCCCC;
  color: #000;
  text-align: left;
  border-radius: 6px;
  padding: 8px 8px;
  font-size: 18px;
  position: absolute;
  z-index: 10000;
  margin:auto;
  line-height: 1.2;
}

.tooltip-icon:hover .tooltiptext {
  visibility: visible;
}

.error-message {
  color: red;
  font-style: italic;
}

.thankyou-checkbox-element {
  width: 80%;
  font-size: 14px;
}

.changeamount-button-element {
  background-color: #FF9718; 
  margin-right: 20px;
  width: 45%;
}

.sign-agreement-button-element {
  margin-left: auto;
  width: 55%;
}

.btn {
  font-size: 18px;
  color: white;
  font-weight: bold;
}

.textarea-input {
  width: 100%;
  height: 10%
}

.check-box {
  width: 20px;
  height: 20px;
}

.btn:disabled {
  background-color: #BBC74D;
  opacity: 0.5;
  border: none;
}

.button-group-element {
  display: flex;
}
.next-button {
  margin-left: 20px;
}

.resend-label-element {
  width: 75%;
}

.resend-code {
  display: flex;
  margin-top: 15px;
}
.resend-code-text{
  cursor: pointer;
  pointer-events: auto;
  text-decoration: underline;
}
.resend-code-disable{
  pointer-events: none;
  text-decoration: underline;
}
.resend-code-text:hover {
  color: #3860B2;
}

.resend-code-text:focus {
  color: red;
}

@media only screen and (max-width: 900px) {
  .resend-code {
    display: block;
  }

  .resend-button {
    width: 100%;
  }

  .resend-label-element {
    width: 100%;
  }  

}

@media only screen and (max-width: 1000px) {
 
  .form-element {
    width: 90%;
  }

  .changeamount-button-element,
  .sign-agreement-button-element,
  .next-disable-button-element,
  .next-enable-button-element {
    width: 100%;
    margin-bottom: 20px;
  }

  .sign-agreement-button-element,
  .next-button {
    margin-left: 0;
  }
  .back-button {
    margin-right: 20px;
    background-color:#000;
  }
  .changeamount-button-element,
  .back-button {
    margin-bottom: 20px;
  } 
 
}

@media only screen and (max-width: 500px), (max-width: 320px) {
  .btn {
    font-size: 14px;
  }
  .form-element {
    width: 90%;
  }  
}

.address-manual-span{
  cursor: pointer;
}

.address-manual-span:hover{
  color: #FF9718;
  text-decoration: underline;
}
.submit-button-div{
  position: relative;
}
.ds-clickwrap{
  min-height: 100px;
  position: relative;
}

.aggreementLoader{
  position: absolute;
  top: 40%;
  left: 40%;
}
.aggreementLoaderText{
  top: 70%;
  left: 40%;
}
.buttonLoaderdiv{
  position: absolute;
  top: 85%; 
  left: 40%;
  bottom: 100px;
}
.buttonLoaderdivText{
  top: 80%;
  left: 40%;
}
.loader { 
  margin:0 auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #FF9718;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  opacity: 0.5;
  z-index: 1000;
}
.loading-text {
  position: absolute;  
  z-index: 1000;
  font-weight: bold;
}

.suggestion{
  cursor: pointer;  
  margin: 2px;
}

.suggestion:hover{
  background-color: #FF9718;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-bool-enable {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.blur-background { 
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -ms-filter: blur(1px);
  -o-filter: blur(px);
  filter: blur(1px);
}
.col-sm-5 {
  padding-right: 0 !important;
}

.other-input-field{
  height: 30px !important;
  border-radius: 0px !important;
  margin-top: 10px;
  border: 1px solid rgb(199, 197, 197) !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.circle-xmark-element {
  padding: 2px 0 0 10px;
  cursor: pointer;
}
.dobDiv input{
  width: 100%;
}

.autocomplete-element div{
  width: 100%;
  max-height: 110px!important;
}
.autocomplete-element div div{
  max-height: 178px !important;
  position: static !important;
  overflow-y: auto;
}
 
 .DraftEditor-editorContainer {
  max-height: 200px;
  min-height: 200px;
  overflow-y: scroll;
}

.EditorToolbar__root___3_Aqz {
  pointer-events: none;
  opacity: 0.4;
}

.notranslate.public-DraftEditor-content {
  pointer-events: none;
}

.css-3ddbcp iframe{
  background-color: #fff;
}
.css-3ddbcp{
  position: relative !important; 
}


.badge {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: 1px solid black;
  margin-bottom: 10px;
  padding-top: 12px;
  cursor: pointer;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title]:after {
  content: attr(data-title);
  background-color: #FF9718;
  color: #111;
  position: absolute;
  padding: 1px 5px 2px 5px;
  left: 100%;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 99999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}

.sidemenu-element {
  margin-right: 20px;
}

.filter-button {
  padding: 7px 0;
  background: #fff;
  border: 1px solid #999999;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
  width: 5rem;
  margin-bottom: 20px;
  color: #000;
}

.filter-button:focus {
  background: #a6d2f2;
  border: 1px solid #3daefe;
}

.left-section {
  display: flex;
  height: 25px
}

.small-text-field {
  height: 30px !important;
}

.bracket{
  line-height: 0.8;
  margin-bottom: 20px;
  font-size: 20px;
}

.bracket-save{
  font-weight: bold;
  text-decoration: underline;
}

.bracket-payment{
  font-style: italic;
  font-weight: bold;
}

.spend-image {
  height: 40px;
}

.row-main {
  display: flex;
  width: 60%;
}

.green-btn {
  background-color: #BBC74D;
}


@media only screen and (max-width: 576px){
  .filter-button-main {
    width: 8rem;
  }
  .filter-button-row {
    margin-left: 3rem;
  }
  .thankyou-checkbox-element {
    width: 90%;
    font-size: 12px;
  }
  .row-main {
    width: 90%;
  }
  .tooltiptext {
    visibility: hidden;
    background-color: #CCCCCC;
    color: #000;
    text-align: left;
    border-radius: 6px;
    padding: 8px 8px;
    font-size: 18px;
    position: absolute;
    z-index: 10000;
    margin:auto;
    line-height: 1.2;
    left:1px;
  }
 }
 
.bike-img{
width:100px;
height:100px;
}
.address-manual-disable {
  pointer-events: none;
}

.card-network-popup-overlay {
  background-color: grey;
  color: white;
  padding: 20px 20px 20px 20px;
  position: absolute;
  top: 25%;
  left: 30%;
  right: 30%;
  border: 1px solid #e68921;
  opacity: 0.9;
}

.fa-exclamation-triangle {
  background-color: yellow;
  padding: 10px;
  font-size: 30px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.ok-button {
  width: 35%;
  background-color: green;
  border: none;
  color: white;
  height: 35px;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 25px;
}
span[disabled] { pointer-events: none; }

/* header */
.header-logo {
  width: 120px;
  height: 30px;
}

.header-menu-image {
  width: 40px; 
  height: 40px;
  margin: auto;
  margin-bottom: 10px;
}
.header-location-image {
  width: 30px;
  height: 40px;
  margin: auto;
}

.header-account-image {
  width: 40px;
  height: 40px;
  margin: auto;
}

.header-menu-text {
  color: white;
  font-size: 12px;
}

.navbar-expand-sm {
  background-color: #242424;
}

.locations-order-element {
  order: 1;
  margin-right: 20px;
}

.account-order-element {
  order: 2;
  margin-right: 20px;
}

/* footer */
footer {
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 40px;
}

.footer-main-content {
  font-size: 16px;
  padding-bottom: 20px;
  font-weight: bold;
}

.footer-sub-content {
  font-size: 14px;
  padding-bottom: 5px;
  color: #c6c6c6;
}
.footer-sub-content a{
  color: #c6c6c6;
  text-decoration: none;
}

.footer-left-column {
  background-color: #343434;
  display: flex;
  width: 50%;
  position: relative;
}

.footer-right-column {
  background-color: #242424;
  padding: 2rem 5rem;
  padding-left: 5rem;
  text-align: left;
  width: 50%;
}

.footer-left-icons {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;

}

.footer-row {
  display: flex;
  margin-top: 20px;
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) {
  .footer-follow-icons {
    display: flex;
    justify-content: flex-end;
    padding-right: 40px;
    position :absolute;
    top: 30px;
    right: 0;
  }
}
@media only screen 
and (min-device-width :  1024px) {
  .footer-follow-icons {
    display: flex;
  justify-content: flex-end;
  flex-direction: row;
  position :absolute;
  top: 30px;
  right: 0;
  padding-right: 30px;
  }
}


@media only screen and (max-width: 567px) {
  .footer-row {
    display: block;
    margin-top: 20px;
  }

  .footer-right-column {
    padding: 10px 20px 0 30px;
    width: 100%;
  }

    .footer-left-column {
      width: 100%;
    }

  .footer-follow-icons {
    display: block;
    position: static;
    margin-left: 0;
    margin-top: 20px;
    padding: 20px;
  }

  .navbar-toggler {
    background-color: white;
    margin-right: 20px;
  }

  .locations-order-element {
    order: 2;
    margin-right: 20px;
  }

  .account-order-element {
    order: 1;
    margin-right: 20px;
  }
}

#ds-clickwrap {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

input::-webkit-date-and-time-value {
  margin-top: 0px;
  text-align: left;
}